import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import Config from "../config";
import lock from "../assets/lock.svg";

function CorpApplication(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(true);

  const formSchema1: FieldAttributes[] = [
    {
      name: "fullName",
      label: "Authorized Signer Name",
      type: FieldType.TEXT,
      defaultValue: data.majorityName,
      validation: saveState ? { required: false } : { required: true },
    },
    {
        name: "titleOfAuthSigner",
        label: "Business Title",
        type: FieldType.TEXT,
        defaultValue: data.titleOfAuthSigner,
        validation: saveState ? { required: false } : { required: true },
    },
  ];
  const formSchema2: FieldAttributes[] = [
    {
      name: "businessAddress",
      label: "Home Address",
      type: FieldType.ADDRESS,
      defaultValue: data.homeAddress,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "dateOfBirth",
      label: "Date of Birth",
      type: FieldType.DATE,
      defaultValue: data.dateOfBirth,
      validation: saveState ? { required: false } : { required: true },
    },
    {
        name: "ssNumber",
        label: "Social Security Number (optional)",
        type: FieldType.SSN,
        defaultValue: data.ssNumber,
        validation: { required: false },
        
    },
  ];
  const formSchema3: FieldAttributes[] = [
    {
        name: "authority",
        label: `I have the authority to sign and execute contracts on behalf of ${data.businessName}`,
        type: FieldType.CHECKBOX,
        defaultValue: data.auth,
        validation: { required: true },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    const fullName = values.firstName + " " + values.lastName;

    const saveApplicationStep = async (values: any) => {
      if (!saveState && methods.formState.isValid) {
        setIsLoading(true);
      }
      const payload = {
        Status: ["OWNER_INFO"],
        token: params.hash,
        majorityAddress: values.businessAddress,
        majorityName: values.fullName,
        majorityBirth: values.dateOfBirth,
        majoritySsn: values.ssNumber,
        titleOfAuthSigner: values.titleOfAuthSigner,
        currentFunnelPath:
        (data.currentFunnel === 1 || data.currentFunnel === 2) ? (saveState ? 4 : 5) : (saveState ? 5 : 6)
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    if (methods.formState.isValid && saved) {
        navigate(`/${params.hash}/context`);
      }
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    setSaveState(false);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("Let’s verify your personal information");
    setProgressHeader(true);
    setFormProgress(`w-2/4`);
    setFormProgressText("50%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
    console.log("beforeTrigger", methods.formState.isValid);
    methods.trigger();
    console.log("AfterTrigger", methods.formState.isValid);
  }, []);

  return (
    <>
      <BackButton 
        lastPage={"finance-information"}
        isLoading={isLoading}
      />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <div className="flex flex-row justify-between gap-8 footer-simpl">
            {formSchema1.map((field, index) => (
              <Field key={index} {...field} />
            ))}
          </div>
          {formSchema2.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          {formSchema3.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          <div className="flex flex-row items-center bg-[#F6F5F5] rounded p-3">
          <img className="m-4" src={lock} alt="lock svg" />
            <p className="text-sm text-[#525351]">
              We use bank-level security to protect your data. However, if you
              prefer to talk to your sales representative call us now at{" "}
              <a href="tel:18003293375">
                <u>1 800 329 3375</u>
              </a>
            </p>
          </div>
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default CorpApplication;
