import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import { Step5Data } from "../types";
import SignaturePad from "react-signature-pad-wrapper";
import * as React from "react";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
  submitAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import { Uploader } from "uploader";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import Config from "../config";
import "../styles/style.css"
import eye from "../assets/eye-mask.png"
import LoadingScreen from "../components/Loader/loader";

const upload = Uploader({ apiKey: "public_FW25axZ42W9SnxbXHqBDp6LLrqDT" });


function SignApp(this: any, props: any) {
  const params = useParams();
  const data: any = useLoaderData();
  const navigate = useNavigate();
  const methods = useForm();
  const signaturePadRef = React.useRef() as React.MutableRefObject<any>;
  
  const [noSign, setNoSign] = useState(false)
  const nonOwnerTypes = ["government", "nonprofit", "tribal", "other"];
  const signature = "";
  let stopNav = true;
  
  const formSchema: FieldAttributes[] = [
    {
      name: "terms",
      label: `I have read and agree to these`,
      type: FieldType.CHECKBOX,
      defaultValue: "",
      validation: { required: true },
    },
  ];

  const clearCanvas = () => {
    signaturePadRef.current.clear();
  };
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const onSubmitHandler = async (values: any) => {
    if (stopNav === false) {
      setIsLoading(true)
      setShowLoadingScreen(true);
    };
    const dataURL = signaturePadRef.current.toDataURL("image/png", 0.5);
    const filename = "signature.png";

    const img = await fetch(dataURL);
    const convertedImage = await img.blob();
    const file = new File([convertedImage], filename);
    const { fileUrl } = await upload.uploadFile(file);
    console.log(fileUrl);
    if (file.size < 3000) {
      setIsLoading(false)
      console.log("empty signature")
      setNoSign(true)
    } else {
    if (stopNav === false) {setIsLoading(true)};
    const saveApplicationStep = async (values: any) => {

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];

      const payload = {
        Status: stopNav ? ["OWNER_INFO2"] : ["SIGN"],
        token: params.hash,
        signature: [
          {
            url: fileUrl,
            filename: filename,
          },
        ],
        signDate: formattedDate,
        currentFunnelPath: data.currentFunnel === 0 && stopNav ? data.currentStep = 6 : data.currentStep = 7
          /* data.currentStep <= 6 ? data.currentStep + 1 : data.currentStep, */
      };

      try {
        const response = await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });

        const responseData = await response.json();
        
        if (responseData.success) {
          const requirements = responseData.requirements || [];
          const needsOptimization = requirements.some((req: string) => 
            req === "3m_bank_statements" || req === "finance_statements"
          );
          
          if (stopNav === false) {
            if (needsOptimization) {
              navigate(`/${params.hash}/optimize`, { replace: true });
            } else {
              navigate(`/${params.hash}/received`, { replace: true });
            }
          }
        } else {
          console.error("Error saving application:", responseData.message);
        }
      } catch (e) {
        console.error("Error saving application:", e);
        return false;
      } return true;
      };
    const saved = await saveApplicationStep(values);
    } 
  };

  const saveHandler = async (values: any) => {
    stopNav = true
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    stopNav = false
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formProgress, setFormProgress] = useAtom(progressAtom);
  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom)
  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };
  const [showBusinessInfo, setShowBusinessInfo] = useState(true);
  const [showLoanInfo, setShowLoanInfo] = useState(true);
  const [showOwnerInfo, setShowOwnerInfo] = useState(true);
  const [showSSN, setShowSSN] = useState(false);

  const toggleSSNVisibility = () => {
    setShowSSN(!showSSN);
  };

  function deduplicateAddress(address: any) {
    if (!address) return '-';
    return Array.from(new Set(address.split(', '))).join(', ');
  }

  useEffect(() => {
    if (showLoadingScreen) {
      setFormHeader(
        "Finalizing your application..."
      )} else {
      setFormHeader(
        "Please review and sign your application"
        );
      };
    setProgressHeader(true);
    setFormProgress(`w-[90%]`);
    setFormProgressText("90%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setShowSubmitModal(false)
    setFormNarrow(false);
  }, []);

  return (
    <>
      {showLoadingScreen && <LoadingScreen />}
      {!showLoadingScreen && (
        <>
          <BackButton
            lastPage={"context"}
            isLoading={isLoading}
          />
        <div className="flex flex-col">
          <div className="mb-4">
            <div 
              className="flex flex-row items-center mb-6 ">
              <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                Business Information
              </span>
              <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
            </div>
            {showBusinessInfo && (<>
            <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
              <div className="text-gray-700 text-sm leading-5 ">Business Name</div>
              <div className="text-gray-900 text-base font-semibold leading-6">{data?.businessName || '-'}</div>
            </div>
            <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
              <div className="text-gray-700 text-sm leading-5 ">Business Address</div>
              <div className="text-gray-900 text-base font-semibold leading-6">{data?.businessAddress ? deduplicateAddress(data.businessAddress) : '-'}</div>
            </div>
            <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
              <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                <div className="text-gray-700 text-sm leading-5 ">Federal Tax ID (EIN)</div>
                <div className="text-gray-900 text-base font-semibold leading-6">{data.taxID || '-'}</div>
              </div>
              <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                <div className="text-gray-700 text-sm leading-5 ">Est. Monthly Revenue</div>
                <div className="text-gray-900 text-base font-semibold leading-6">{data.monthlyRevenue || '-'}</div>
              </div>
            </div>
            </>)}
          </div>
          <div className="mb-4">
            <div 
              className="flex flex-row items-center mb-6 "
              /* onClick={() => setShowLoanInfo(!showLoanInfo)} */>
              <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                Finance Information
              </span>
              <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
            </div>
            {showLoanInfo && (<>
            <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
              <div className="text-gray-700 text-sm leading-5 ">Financed Equipment</div>
              <div className="text-gray-900 text-base font-semibold leading-6">{data.product || '-'}</div>
            </div>
            <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
              <div className="text-gray-700 text-sm leading-5 ">Equipment Storage Location</div>
              <div className="text-gray-900 text-base font-semibold leading-6">{data.shippingAddress || '-'}</div>
            </div>
            <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
              <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                <div className="text-gray-700 text-sm leading-5 ">Equipment Price (excl. taxes)</div>
                <div className="text-gray-900 text-base font-semibold leading-6">{data.equipments.totalPrice.formated || '-'}</div>
              </div>
              <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                <div className="text-gray-700 text-sm leading-5 ">Downpayment</div>
                <div className="text-gray-900 text-base font-semibold leading-6">{data.downpayment || '-'}</div>
              </div>
            </div>
            </>)}
          </div>
          {/* Owner/Authorized Signer Information Section */}
            {nonOwnerTypes.includes(data?.businessType?.toLowerCase()) ? (
              // Authorized Signer Section
              <div className="mb-4">
                <div className="flex flex-row items-center mb-6">
                  <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                    Authorized Contact Information
                  </span>
                  <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                </div>
                {showOwnerInfo && (
                  <>
                    <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                      <div className="text-gray-700 text-sm leading-5">Full Name</div>
                      <div className="text-gray-900 text-base font-semibold leading-6">
                        {data.nameOfAuthSigner}
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                      <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                        <div className="text-gray-700 text-sm leading-5">Business Title</div>
                        <div className="text-gray-900 text-base font-semibold leading-6">
                          {data.titleOfAuthSigner}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                      <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                        <div className="text-gray-700 text-sm leading-5">Email Address</div>
                        <div className="text-gray-900 text-base font-semibold leading-6">
                          {data.emailOfAuthSigner}
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                        <div className="text-gray-700 text-sm leading-5">Phone Number</div>
                        <div className="text-gray-900 text-base font-semibold leading-6">
                          {data.phoneOfAuthSigner}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : ( 
              // Original Owner Information Sections
              <>
                {/* Primary Owner Information */}
                <div className="mb-4">
                  <div className="flex flex-row items-center mb-6">
                    <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                      {data.corpApplication ? "Corporate Contact Information" : "Owner Information"}
                    </span>
                    <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                  </div>
                  {showOwnerInfo && (
                    <>
                      <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                        <div className="text-gray-700 text-sm leading-5">Full Name</div>
                        <div className="text-gray-900 text-base font-semibold leading-6">
                          {data.firstName} {data.lastName}
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                        <div className="text-gray-700 text-sm leading-5">Personal Address</div>
                        <div className="text-gray-900 text-base font-semibold leading-6">
                          {data.homeAddress || '-'}
                        </div>
                      </div>
                      <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                          <div className="text-gray-700 text-sm leading-5">Date of Birth</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">
                            {data.dateOfBirth || '-'}
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                          <div className="text-gray-700 text-sm leading-5">Email Address</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">
                            {data.majorityEmail}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                          <div className="text-gray-700 text-sm leading-5">
                            {data.corpApplication ? "Business Title" : "Business Ownership %"}
                          </div>
                          <div className="text-gray-900 text-base font-semibold leading-6">
                            {data.corpApplication ? data.titleOfAuthSigner : `${data.majorityOwnership}%`}
                          </div>
                        </div>
                        {data.ssNumber && (
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Social Security Number (SSN)</div>
                            <div className="flex text-gray-900 text-base font-semibold leading-6 items-center">
                              {showSSN ? data.ssNumber : '***-**-****'}
                              <button onClick={toggleSSNVisibility} className="ml-2 items-center">
                                <img className="w-8" src={eye} alt="toggle visibility" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {/* Second Owner Information */}
                {data.secOwner && (
                  <div className="mb-4">
                    <div className="flex flex-row items-center mb-6">
                      <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                        Second Owner Information
                      </span>
                      <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                    </div>
                    {showOwnerInfo && (
                      <>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Full Name</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.secOwner}</div>
                        </div>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Personal Address</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.secAddress || '-'}</div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Date of Birth</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.secBirth || '-'}</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Email Address</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.secEmail}</div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Business Ownership %</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.secOwnership}%</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Social Security Number (SSN)</div>
                            <div className="flex text-gray-900 text-base font-semibold leading-6 items-center">
                              {showSSN ? data.secSsn || 'TBD' : '***-**-****'}
                              <button onClick={toggleSSNVisibility} className="ml-2 items-center">
                                <img className="w-8" src={eye} alt="toggle visibility" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Third Owner Information */}
                {data.thirdOwner && (
                  <div className="mb-4">
                    <div className="flex flex-row items-center mb-6">
                      <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                        Third Owner Information
                      </span>
                      <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                    </div>
                    {showOwnerInfo && (
                      <>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Full Name</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.thirdOwner}</div>
                        </div>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Personal Address</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.thirdAddress || '-'}</div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Date of Birth</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.thirdBirth || '-'}</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Email Address</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.thirdOwnerEmail}</div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Business Ownership %</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.thirdOwnership}%</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Social Security Number (SSN)</div>
                            <div className="flex text-gray-900 text-base font-semibold leading-6 items-center">
                              {showSSN ? data.thirdSsn || 'TBD' : '***-**-****'}
                              <button onClick={toggleSSNVisibility} className="ml-2 items-center">
                                <img className="w-8" src={eye} alt="toggle visibility" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Fourth Owner Information */}
                {data.fourthOwner && (
                  <div className="mb-4">
                    <div className="flex flex-row items-center mb-6">
                      <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                        Fourth Owner Information
                      </span>
                      <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                    </div>
                    {showOwnerInfo && (
                      <>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Full Name</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthOwner}</div>
                        </div>
                        <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6">
                          <div className="text-gray-700 text-sm leading-5">Personal Address</div>
                          <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthdAddress || '-'}</div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Date of Birth</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthBirth || '-'}</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Email Address</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthOwnerEmail}</div>
                            <div className="text-gray-700 text-sm leading-5">Email Address</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthOwnerEmail}</div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 justify-items-stretch mobile-sign-container">
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Business Ownership %</div>
                            <div className="text-gray-900 text-base font-semibold leading-6">{data.fourthOwnership}%</div>
                          </div>
                          <div className="flex flex-col items-start gap-1 self-stretch rounded bg-gray-100 px-4 py-2 mb-6 w-full">
                            <div className="text-gray-700 text-sm leading-5">Social Security Number (SSN)</div>
                            <div className="flex text-gray-900 text-base font-semibold leading-6 items-center">
                              {showSSN ? data.fourthSsn || 'TBD' : '***-**-****'}
                              <button onClick={toggleSSNVisibility} className="ml-2 items-center">
                                <img className="w-8" src={eye} alt="toggle visibility" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          <div>
            
            <div className="flex flex-row items-center">
              <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                Release & Authorization
              </span>
              <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
            </div>
            <p className="font-normal text-xs leading-[21px] text-[#525351] py-4">
              By submitting this application for credit, I, and any person for
              whom information is provided, agree and warrant that I am and we are
              authorized to provide all information submitted, that it is true,
              accurate and complete, and expressly instruct and authorize
              Machinery Partner, its affiliates, assigns and any lending partner
              to which we refer this application, their affiliates, assigns and
              funding sources to obtain and share business and personal credit and
              financial information for the purpose of the transaction for which
              this application is made, including future extensions of credit,
              review, renewal or collection of any resulting account from any and
              all sources deemed necessary, including consumer credit reports,
              trade references or bank account information. Such inquiries may
              appear on my or our credit reports. This application is solely for
              business purposes and not for personal, family or household
              purposes. I agree to receive any notices or communications regarding
              this application or any resulting transaction electronically to the
              email address(es) and mobile telephone numbers provided, including
              marketing through SMS messaging, reserving the right to opt out at
              any time.
            </p>
          </div>
          <div>
            <div className="flex flex-row items-center">
              <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                Equal Opportunity Credit Act Notice
              </span>
              <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
            </div>
            <p className="font-normal text-xs leading-[21px] text-[#525351] py-4">
              If your application for business credit is denied, you have the
              right to a written statement of the specific reasons for the denial.
              To obtain the statement, please contact our Credit Administrator,
              Machinery Partner Inc, 131 Dartmouth St, Boston, MA 02116. We
              will send you a written statement detailing the reasons for the
              denial within 30 days of receiving your request for the statement.
              The Federal Equal Credit Opportunities Act (ECOA) prohibits
              creditors discrimination against credit applicants on the basis of
              race, color, religion, national origin, sex, marital status, or age
              (provided the applicant can enter into a binding contract), because
              all or part of the applicant’s income derives from any public
              assistance program, or because the applicant has in good faith
              exercised any right under the Consumer Credit Protection Act. The
              federal agency that administers our compliance with this law is the
              Federal Trade Commission, Equal Credit Opportunity, Washington DC
              20580.
            </p>
          </div>
          <div>
            <FormProvider {...methods}>
              <form
                className="form gap-0 pt-2"
                onSubmit={methods.handleSubmit(onSubmitHandler)}
              >
                {formSchema.map((field, index) => (
                  <Field key={index} {...field} />
                ))}
                <div className="flex flex-row font-semibold text-base leading-6 text-[#171718] mt-6 mb-2">
                  Signature<div className="pl-1 text-[#FA5252]">*</div>
                </div>
                <div className={` flex justify-start shadow-[inset_0px_2px_4px_rgba(0,0,0,0.06)] rounded border-2 border-solid ${noSign ? 'border-[#FA5252]' : 'border-neutral-300'}`}>
                  <SignaturePad
                    ref={signaturePadRef}
                    height={200}
                    options={{
                      minWidth: 1,
                      maxWidth: 3,
                      penColor: "rgb(0, 0, 0",
                    }}
                  />
                </div>
                <div className="flex flex-row mt-2 mb-6 space-between">
                  <button
                    className="text-[#228BE6] border-[none] font-semibold w-[120px] "
                    onClick={clearCanvas}
                  >
                  Clear signature
                  </button>
                  {noSign ? 
                    <span className="left-0 min-h-[0.25rem] self-center text-[#FA5252] text-sm ml-4">
                    Signature is required.
                    </span> : <></>
                  }
                </div>
                {/* <button type='reset' className="btn-secondary" onClick={saveSignature}>Save</button> */}
                <ButtonContainer
                  button1={"Save & Finish Later"}
                  button2={"Next"}
                  loading={isLoading ? true : false}
                  navigate={navigateHandler}
                  save={saveHandler}
                />
              </form>
            </FormProvider>
          </div>
        </div>
        </>
        )}
    </>
  );
}

export default SignApp;
