import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
  submitAtom,
} from "../App";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import upload from "../assets/Upload.svg";
import context from "../assets/Context.svg";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect, useState } from "react";
import completed from "../assets/completed.png";
import { Step8Data } from "../types";
import { useForm } from "react-hook-form";
import Config from "../config";
import SubmitOptimize from "../components/Pages/Layout/submit_optimize";

function Optimize(props: any) {
  const params = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);
  const [isStartUp, setIsStartUp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [requirements, setRequirements] = useState<string[]>([]);

  let businessData = data.businessContext;
  let businessReason = data.businessReason;
  let docuData = data.documents;
  let startUpData = data.managementExp;
  let taxData = data.taxID

  useEffect(() => {
    if (data.requirements) {
      const reqArray = data.requirements.split(',').map((item: string) => item.trim());
      const formattedReq: string[] = [];
      
      if (reqArray.includes('3m_bank_statements')) {
        formattedReq.push('3 months of bank statements');
      }
      
      if (reqArray.includes('finance_statements')) {
        formattedReq.push('2 years of Business Tax Returns (or internal statements)');
      }
      
      formattedReq.push('Business context');
      
      setRequirements(formattedReq);
    }
  }, [data.requirements]);

  const optimizeLogic = () => {
    if (data.currentFunnel === 0) {
      return 8;
    } else if (data.currentFunnel === 1) {
      return 9;
    } else if (data.currentFunnel === 2) {
      return 17;
    } else if (data.currentFunnel === 3) {
      return 11;
    } else if (data.currentFunnel === 4) {
      return 19;
    }
  }
  const [pathNr, setPathNr] = useState(0);

  const backHandler = async () => {
    let newPathNr = 0;
    if (data.currentFunnel === 0) {
      newPathNr  = 6;
    } else if (data.currentFunnel === 1) {
      newPathNr  = 7;
    } else if (data.currentFunnel === 2) {
      newPathNr  = 15;
    } else if (data.currentFunnel === 3) {
      newPathNr  = 9;
    } else if (data.currentFunnel === 4) {
      newPathNr  = 17;
    }
    setPathNr(newPathNr);
  }

  const saveHandler = async (values: any) => {
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    if (
      (isStartUp && startUpData && docuData) ||
      (!isStartUp && docuData && businessData && businessReason)
    ) {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      const payload = {
        Status: ["OPTIMIZE"],
        token: params.hash,
        currentFunnelPath: optimizeLogic(),
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);

    navigate(`/${params.hash}/in-review`);
    } else {
      setShowSubmitModal(true)
      setShowSubmitContent(true)
    }
  };

  const uploadHandler = async (values: any) => {
    navigate(`/${params.hash}/bank-statements`);
  };

  const contextHandler = async (values: any) => {
    navigate(`/${params.hash}/context`);
  };

  const startUpHandler = async (values: any) => {
    navigate(`/${params.hash}/context-question-01`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom)
  const [showSubmitContent, setShowSubmitContent] = useState(false)

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  const SubmitToggler = () => {
    setShowSubmitModal(!showSubmitModal);
    setShowSubmitContent(!showSubmitContent)
  };

  useEffect(() => {
    setFormHeader("Final Step: Build your credit profile");
    setProgressHeader(false);
    setFormButtonShow(``);
    setformDocuBar(true);
    setDocProgress("step2");
    setFormNarrow(false);
    setShowSubmitModal(false);
    backHandler();
    setIsStartUp(false);
  }, []);

  return (
    <>
      {showSubmitContent && <SubmitOptimize toggle={SubmitToggler} submit={undefined} optimize={undefined} />}
      <div className="font-normal text-base text-[#525351]">
      Based upon the amount or your credit profile so far, to increase your likelihood of approval and lower your rate, please provide the following: <br/>
        <br/>
        <ul className="list-none pl-0">
          {requirements.map((req, index) => (
            <li key={index} className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad flex items-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-2 flex-shrink-0">
                <path d="M12.1016 13.1001C12.6538 13.1001 13.1016 12.6524 13.1016 12.1001C13.1016 11.5478 12.6538 11.1001 12.1016 11.1001C11.5493 11.1001 11.1016 11.5478 11.1016 12.1001C11.1016 12.6524 11.5493 13.1001 12.1016 13.1001Z" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              {req}
            </li>
          ))}
        </ul>
        <br/> Need help? Call us&nbsp;
        <a href="tel:18003293375">
          <b><u className="text-[#228BE6]">+1-800-329-3375</u></b>
        </a>
      </div>
      <div className="grid grid-cols-[1fr_6fr_2fr] flex-row p-6 mb-6 border-neutral-300 rounded border-2 border-solid h-[88px] items-center justify-center mt-6 mobile-widget">
        {docuData ? (
          <img className="w-10 h-10 rounded" src={completed} />
        ) : (
          <img className="w-10 h-10 rounded" src={upload} />
        )}
        <div className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad">
          Upload documents
        </div>
        <div
          onClick={uploadHandler}
          className="w-[125px] h-10 flex flex-row justify-center items-center px-6 py-2 rounded border-2 border-solid border-[#171718] font-semibold text-base leading-6 cursor-pointer transition-[0.2s,] hover:bg-[#FFFAEB] hover:border-[#FF9900]"
        >
          Upload
        </div>
      </div>
{/*         <div className="grid grid-cols-[1fr_6fr_2fr] flex-row p-6 border-neutral-300 rounded border-2 border-solid h-[88px] items-center justify-center mb-8 mobile-widget">
          {businessData && businessReason && taxData ? (
            <img className="w-10 h-10 rounded" src={completed} />
          ) : (
            <img className="w-10 h-10 rounded" src={context} />
          )}
          <div className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad">
            Tell us more about your business
          </div>
          <div
            onClick={contextHandler}
            className="w-[125px] h-10 flex flex-row justify-center items-center px-6 py-2 rounded border-2 border-solid border-[#171718] font-semibold text-base leading-6 cursor-pointer transition-[0.2s,] hover:bg-[#FFFAEB] hover:border-[#FF9900]"
          >
            Tell more
          </div>
        </div> */}
      <ButtonContainer
        button1={"Save & Finish Later"}
        button2={"Submit for Review"}
        loading={isLoading ? true : false}
        navigate={navigateHandler}
        save={saveHandler}
      />
    </>
  );
}

export default Optimize;
