import { useNavigate, useParams } from "react-router-dom";
import sign from "../../../assets/Icon-chevron-left.svg";
import "../../../styles/style.css";
import { atom, useAtom } from 'jotai';
import { useEffect } from "react";

export const bbuttonAtom = atom(``)

function BackButton({ lastPage, isLoading }: { lastPage: string, isLoading?: boolean }) {
  const params = useParams();
  const navigate = useNavigate();
  const [formButtonShow] = useAtom(bbuttonAtom);

  const handleNavigation = () => {
    navigate(`/${params.hash}/${lastPage}`);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleNavigation);
    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, []);

  return (
    <div className={`relative left-[-4rem] top-[-7rem] w-full max-w-screen-sm mx-auto lettering ${formButtonShow}`}>
      <div 
        onClick={handleNavigation}
        className="absolute flex left-[-7rem] w-28 items-center gap-x-3 gap-y-3 text-[#171718] font-semibold no-underline pl-3 pr-6 py-3 top-8 cursor-pointer"
      >
        {isLoading ? (
          <>
            <svg 
              className="animate-spin h-5 w-5" 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#171718"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
            </svg>
            <span>Loading...</span>
          </>
        ) : (
          <>
            <img src={sign} loading="lazy" alt="back icon" />
            <span>Back</span>
          </>
        )}
      </div>
    </div>
  );
}

export default BackButton;