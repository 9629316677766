import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import "../styles/App.css";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import { Step1Data } from "../types";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

function BusinessInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const data: any = useLoaderData();

  const [saveState, setSaveState] = useState(true);
  let defaultAddress =
    data.businessAddress &&
    data.businessCity &&
    data.businessState &&
    data.businessZip
      ? data.businessAddress +
        ", " +
        data.businessCity +
        ", " +
        data.businessState +
        ", " +
        data.businessZip
      : "";

  const formSchema: FieldAttributes[] = [
    {
      name: "businessName",
      label: "Business Name",
      type: FieldType.TEXT,
      defaultValue: data.businessName,
      /* validation: {required: true}, */
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "businessAddress",
      label: "Business Address",
      type: FieldType.ADDRESS,
      defaultValue: data.businessAddress,
      validation: {
        required: true,
        onChange: (e: any) => {
          console.log("e", e);
        },
      },
    },
     {
       name: "taxID",
       label: "Federal Tax ID (EIN)",
       type: FieldType.EIN,
       defaultValue: data.taxID,
       /* validation: {required: true}, */
       validation: { required: false },
      },
    /*     {
      name: "email",
      label: "Email",
      type: FieldType.EMAIL,
      defaultValue: "",
      validation: { required: true },
    }, */
    {
      name: "monthlyRevenue",
      label: "Est. Monthly Revenue ",
      type: FieldType.CURRENCY,
      defaultValue: data.monthlyRevenue,
      validation: { required: false },
    },
    /*     {
      name: "ssn",
      label: "Social Security Number",
      type: FieldType.SSN,
      defaultValue: "",
      validation: { required: true },
    }, */
    /*     {
      name: "equipment",
      label: "Select equipment",
      type: FieldType.SELECT,
      defaultValue: data.equipment,
      mode: "LIST",
      validation: { required: true },
      options: [
        { label: "Equipment 1", value: "equip1" },
        { label: "Equipment 2", value: "equip2" },
        { label: "Equipment 3", value: "equip3" },
        { label: "Equipment 4", value: "equip4" },
      ],
    }, */
  ];
  const formSchema2: FieldAttributes[] = [
    {
      name: "timeInBusiness",
      label: "Years in Business",
      type: FieldType.TEXT,
      defaultValue: data.timeInBusiness,
      /* validation: {required: true}, */
      validation: saveState ? { required: false } : { required: true },
    },
  ];

  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    setSaveState(false);

    if (!saveState && methods.formState.isValid) {
      navigate(`/${params.hash}/finance-information`);
    }

    const saveApplicationStep = async (values: any) => {
      if (!saveState && methods.formState.isValid) {
        setIsLoading(true);
      }

      if (methods.formState.isValid) {
          navigate(`/${params.hash}/finance-information`);
      }
      
      const yIB = parseInt(values.timeInBusiness) || data.timeInBusiness
      const payload = {
        Status: ["BUSINESS_INFO"],
        token: params.hash,
        companyName: values.businessName,
        majorityEmail: data.contactEmail,
        ein: values.taxID,
        address: values.businessAddress,
        monthlyRevenue: values.monthlyRevenue,
        timeInBusiness: yIB,
        funnelType: parseInt(values.timeInBusiness) > 2 ? 1 : 2,
        currentFunnelPath:
          saveState === true ? (data.currentStep = 1) : (data.currentStep = 2),
        /*             ? data.currentStep
            : data.currentStep <= 1
            ? data.currentStep + 1
            : data.currentStep, */
      };
      try {
        fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    };
    saveApplicationStep(values);
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    setSaveState(false);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("First, let’s review your business information");
    setProgressHeader(true);
    setFormProgress(`w-[10%]`);
    setFormProgressText("10%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(false);
    console.log("beforeTrigger", methods.formState.isValid);
    methods.trigger();
    console.log("AfterTrigger", methods.formState.isValid);
  }, []);

  return (
  <>
    <BackButton 
      lastPage={"entity-type"}
      isLoading={isLoading}
      />
    <FormProvider {...methods}>
      <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
        {formSchema.map((field, index) => (
          <Field key={index} {...field} />
        ))}
        {!data.timeInBusiness ? (
          formSchema2.map((field, index) => (
            <Field key={index} {...field} />
          )))
        : (
          null
        )
        }
        <ButtonContainer
          button1={"Save & Finish Later"}
          button2={"Looks Good"}
          loading={isLoading ? true : false}
          navigate={navigateHandler}
          save={saveHandler}
        />
      </form>
    </FormProvider>
  </>
  );
}

export default BusinessInfo;
